import tokens from '@/_generated/tokens/tokens-mix.json';
import octium from '@/assets/images/networks/Fiaticon.svg';
// import contracts from '@/_generated/contracts/contract-abi-etc.json';

export default {
  name: 'MIX',
  name_long: 'MIX Chain',
  homePage: 'https://miexs.com/',
  blockExplorerTX: 'https://miexs.com/tx/[[txHash]]',
  blockExplorerAddr: 'https://miexs.com/address/[[address]]',
  chainID: 761412,
  tokens: tokens,
  contracts: [],
  icon: octium,
  currencyName: 'MIX',
  isTestNetwork: false,
  isEthVMSupported: {
    supported: false,
    url: null,
    websocket: null
  },
  gasPriceMultiplier: 1,
  canBuy: false,
  coingeckoID: null
};
